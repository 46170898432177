var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analysis-page"},[_c('Transition',{attrs:{"name":"slate","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.detailShow),expression:"!detailShow"}]},[_c('div',{staticClass:"analysis-page__header _flex _flex-align__center _flex-justify__between"},[_c('div',{staticClass:"analysis-page__tabs _flex"},[_vm._l((_vm.tabs),function(item,index){return _c('div',{key:item.label,class:[
              'analysis-page__tabs__item _font-weight__600 _font-size__sub__heading _pointer',
              { active: index == _vm.tabsIndex }
            ],on:{"click":function($event){_vm.tabsIndex = index
              _vm.componentName = item.componentName}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),_c('div',{staticClass:"analysis-page__tabs__border",style:(_vm.borderStyle)})],2),_c('div',[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.componentName === 'defects'),expression:"componentName === 'defects'"}],attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
                path: '/view-pdf',
                query: {
                  code: _vm.$route.query.code,
                  src: _vm.towerDetail.reportFilePath
                }
              })}}},[_vm._v(" Download PDF Report ")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.componentName === 'tilt'),expression:"componentName === 'tilt'"}],attrs:{"type":"info","disabled":!_vm.tiltAngleTotal},on:{"click":_vm.handleDownCsv}},[_vm._v(" Download CSV ")])],1)]),_c('Transition',{attrs:{"name":"slate","mode":"out-in"}},[_c('KeepAlive',[_c(_vm.componentName === 'tilt' ? 'tilt-angle-issues' : 'issues-defects',{ref:"children",tag:"Component",attrs:{"code":_vm.$route.query.code,"componentName":_vm.componentName},on:{"view-detail":_vm.handleViewDetail,"total":function($event){_vm.tiltAngleTotal = Number($event)}}})],1)],1)],1)]),_c('Transition',{attrs:{"name":"slate","mode":"out-in"}},[_c('TowerCameraDetail',{directives:[{name:"show",rawName:"v-show",value:(_vm.detailShow),expression:"detailShow"}],attrs:{"source":"3","imgDetail":_vm.imgDetail,"showDefectId":_vm.imgDetail.defectId,"oldS3Key":_vm.oldS3Key},on:{"change":function($event){_vm.detailShow = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="analysis-page">
    <!-- relative 需放在 TowerNav 组件下，不然会影响到组件 -->
    <Transition name="slate" mode="out-in">
      <div v-show="!detailShow">
        <div class="analysis-page__header _flex _flex-align__center _flex-justify__between">
          <div class="analysis-page__tabs _flex">
            <div
              v-for="(item, index) in tabs"
              :key="item.label"
              :class="[
                'analysis-page__tabs__item _font-weight__600 _font-size__sub__heading _pointer',
                { active: index == tabsIndex }
              ]"
              @click="
                tabsIndex = index
                componentName = item.componentName
              "
            >
              {{ item.label }}
            </div>

            <!-- 红色线 -->
            <div class="analysis-page__tabs__border" :style="borderStyle"></div>
          </div>
          <div>
            <el-button
              v-show="componentName === 'defects'"
              type="primary"
              @click="
                $router.push({
                  path: '/view-pdf',
                  query: {
                    code: $route.query.code,
                    src: towerDetail.reportFilePath
                  }
                })
              "
            >
              Download PDF Report
            </el-button>
            <!-- <el-button v-show="$route.query.tabsIndex" type="info" @click="$router.back()">
              Back
            </el-button> -->
            <el-button
              v-show="componentName === 'tilt'"
              type="info"
              @click="handleDownCsv"
              :disabled="!tiltAngleTotal"
            >
              Download CSV
            </el-button>
          </div>
        </div>
        <Transition name="slate" mode="out-in">
          <KeepAlive>
            <Component
              ref="children"
              :is="componentName === 'tilt' ? 'tilt-angle-issues' : 'issues-defects'"
              :code="$route.query.code"
              @view-detail="handleViewDetail"
              @total="tiltAngleTotal = Number($event)"
              :componentName="componentName"
            ></Component>
          </KeepAlive>
        </Transition>
      </div>
    </Transition>

    <Transition name="slate" mode="out-in">
      <TowerCameraDetail
        v-show="detailShow"
        source="3"
        :imgDetail="imgDetail"
        :showDefectId="imgDetail.defectId"
        :oldS3Key="oldS3Key"
        @change="detailShow = false"
      />
    </Transition>
  </div>
</template>

<script>
import { ROUTER_NAME } from '@/router'
import { alertFindDetail, findPage } from '@/api/home'
import { downloadImage } from '@/utils/batch-save'
import { findPageOutCSV } from '@/api/data'
import { parseTime } from '@/utils'
import common from '@/common'
import IssuesDefects from './components/issues-defects'
import { tabs } from './index-data'

export default {
  name: 'analysis',
  components: {
    IssuesDefects,
    // eslint-disable-next-line import/extensions
    TiltAngleIssues: () => import('./components/tilt-angle-issues.vue'),
    // eslint-disable-next-line import/extensions
    TowerCameraDetail: () => import('../data/components/tower-camera-detail.vue')
  },
  data() {
    return {
      componentName: 'Issues',
      domPosition: [],
      tabsIndex: 0,
      tabs,

      towerDetail: {},

      detailShow: false,
      oldS3Key: '',
      imgDetail: {},

      tiltAngleTotal: 0
    }
  },
  watch: {
    '$route.query': {
      handler(val) {
        // 跳转到其他页面也会触发，故此判断 name
        if (this.$route.name === ROUTER_NAME.ANALYSIS) {
          // 区分是否直接显示详情页面
          if (val.type === '0') {
            alertFindDetail(val.id).then((res) => {
              console.log(res, 'res')
              res.result.defectList = res.result.photoDefectList
              delete res.result.photoDefectList
              this.imgDetail = res.result
              this.oldS3Key = res.result.originalPhotoS3Key
              this.detailShow = true
            })
          }

          // 根据不同塔类型更改不同板块
          if (val.catagories === '1' || val.catagories === undefined) {
            this.tabs = [tabs[0], tabs[2]]
            this.componentName = this.tabsIndex === 1 ? 'tilt' : 'Issues'
          } else {
            this.tabs = [tabs[1]]
            this.componentName = 'defects'
          }

          if (val.tabsIndex) {
            this.tabsIndex = val.tabsIndex
            this.detailShow = false
            this.componentName = this.tabs[val.tabsIndex].componentName
          }

          // 如果query中存在code并且towerDetail没有数据
          console.log(this.towerDetail)
          if (val.code && !this.towerDetail.code) {
            findPage({
              condition: val.code
            }).then((res) => {
              ;[this.towerDetail] = res.result.rows
            })
          }

          this.$nextTick(() => {
            this.getDom()
          })
        }
      },
      immediate: true
    }
  },
  computed: {
    borderStyle() {
      if (this.domPosition.length === 0) return { width: '0px' }

      const style = {
        width: `${this.domPosition[this.tabsIndex].width}px`,
        transform: `translateX(${this.domPosition[this.tabsIndex].left}px)`
      }

      return style
    }
  },
  mounted() {
    // home 发起的事件，传递塔详情
    this.$bus.$on('tower-detail', (detail) => {
      this.towerDetail = detail
    })
  },
  methods: {
    getDom() {
      setTimeout(() => {
        this.domPosition = []
        const doms = document.getElementsByClassName('analysis-page__tabs__item')
        for (let i = 0; i < doms.length; i += 1) {
          this.domPosition.push({
            width: doms[i].clientWidth,
            left: doms[i].offsetLeft
          })
        }
      }, 600)
    },
    handleDownCsv() {
      const { formData } = this.$refs.children
      findPageOutCSV({
        beginTime: formData.beginTime,
        endTime: formData.beginTime
          ? `${parseTime(formData.beginTime, '{y}-{m}-{d}')} 23:59:59`
          : '',
        towerCode: common.code
      }).then((res) => {
        downloadImage(res.message)
      })
    },
    handleViewDetail(row) {
      this.oldS3Key = row.originalPhotoS3Key
      this.imgDetail = row
      this.detailShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
.analysis-page {
  &__header {
    width: var(--main-width);
    margin: 0 0 20px;
  }

  &__tabs {
    position: relative;
    height: 36px;

    &__item {
      transition: all 0.3s;
      line-height: 35px;
      &.active {
        color: var(--color-primary);
      }
    }

    &__item + .analysis-page__tabs__item {
      margin-left: 40px;
    }

    &__border {
      position: absolute;
      transition: all 0.3s;
      bottom: 0;
      left: 0;
      height: 3px;
      background: var(--color-primary);
    }
  }

  ::v-deep .el-form {
    margin-bottom: 20px;
    &-item {
      margin-right: 25px;
      margin-bottom: 0px;
      &__label,
      &__content,
      .el-input__icon,
      .el-input__inner {
        box-sizing: border-box;
        line-height: 21px;
      }

      &__label {
        color: var(--color-black);
        font-weight: 600;
        font-size: 12px;
        padding-right: 25px;
      }
      &__content {
        height: 22px;
        box-sizing: border-box;
        border-bottom: 1px solid var(--color-info);
        border-radius: 0px;
      }
      .el-input__suffix {
        right: 0;
      }
      .el-input__icon {
        width: 18px;
      }
    }

    .el-input__inner {
      height: 21px;
      color: var(--color-black);
      border: none;
      background: rgba(0, 0, 0, 0);
      font-size: 12px;
      font-weight: 600;
      padding: 0;
      &::placeholder {
        color: var(--color-black);
      }
    }
    .el-input {
      width: 160px;
    }
    .el-select {
      width: 160px;
      .el-select__caret.el-icon-arrow-up::before {
        content: '\e78f';
        color: var(--color-black);
      }
    }
  }

  .tower-detail {
    width: var(--main-width);
  }
}
</style>
